import { parseCookies } from "nookies";

import { getDestinationFromSessionStorage } from "shared/utils/handleURLInSessionStorage";
import {
  getCurrentPageHref,
  getPrevURL,
  getURLNotFoundFromCookies,
  getWhichErrorIsInPage,
} from "shared/utils/monitoringPageUtils";

export const useGetBrowserInfoToMonitoring = () => {
  const makeErrorMessage = (): string => {
    const currentPageHref = getCurrentPageHref();
    const { username } = parseCookies();
    const prevURL = getPrevURL();
    const destinationUrl = getDestinationFromSessionStorage();
    const erro = getWhichErrorIsInPage();
    const urlNotFound = getURLNotFoundFromCookies();

    const shouldShowPreviousURL = !currentPageHref?.includes(prevURL);

    const lines = [
      `\n*Origem:* ${currentPageHref}`,
      `\n*Usuário:* ${
        username ? username?.replaceAll('"', "") : "Não existe usuário logado."
      }`,
      `\n*URL acessada anteriormente:* ${
        shouldShowPreviousURL
          ? prevURL
          : "Página acessada por link direto ou redirecionada pelo server-side."
      }`,
      `${destinationUrl ? `\n*URL de destino:* ${destinationUrl}` : ""}`,
      `\n*Erro:* ${erro}`,
      urlNotFound ? `\n*URL não encontrada:* ${urlNotFound}` : "",
    ];

    const logText = lines.reduce(
      (currentText, currentLine) => currentText + currentLine,
      "",
    );

    return logText;
  };

  return {
    makeErrorMessage,
  };
};
