import { captureException, captureMessage } from "@sentry/nextjs";

export const cleanUnecessaryDataFromQueries = (queries: string): string => {
  let result = queries;

  try {
    result = queries
      .replace(/.*?\/g/, "/g")
      .replaceAll("nxtP", "")
      .replace("tenant=g&", "")
      .replace("}", "");
  } catch (error) {
    console.log("Erro (cleanUnecessaryDataFromQueries): ", error);

    captureException(error);
    captureMessage("Erro (cleanUnecessaryDataFromQueries)");
  }
  return result;
};
