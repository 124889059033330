import { ComponentType } from "react";

import { captureException, captureMessage } from "@sentry/nextjs";

const ATTEMPTS_LEFT = 2;

const isSessionStorageAvailable = typeof sessionStorage !== "undefined";

const setPageWasForcedToReload = () =>
  sessionStorage?.setItem(
    "page-has-been-forced-to-refresh-by-component-loader",
    "true",
  );

const setReloadedComponents = (refreshedComponents: Set<string>) =>
  sessionStorage?.setItem(
    "reloaded-components",
    JSON.stringify(Array.from(refreshedComponents)),
  );

const setComponentLoaderAttemptsLeft = (attempts: number) =>
  sessionStorage?.setItem("component-loader-attempts-left", `${attempts - 1}`);

const failedToAccessSessionStorage = (error: Error) =>
  console.error("Failed to access localStorage", error);

const componentLoader = (
  lazyComponent: () => Promise<{ default: ComponentType<any> }>,
): Promise<{ default: ComponentType<any> }> => {
  const stringifiedFunction = lazyComponent?.toString();
  let reloadTimeoutId: NodeJS.Timeout | null = null;

  const componentsRefreshed: string[] =
    (isSessionStorageAvailable &&
      JSON.parse(sessionStorage?.getItem("reloaded-components"))) ||
    [];

  const sessionStorageAttemptsLeft: string =
    (isSessionStorageAvailable &&
      JSON.parse(sessionStorage?.getItem("component-loader-attempts-left"))) ||
    ATTEMPTS_LEFT;

  const pageHasAlreadyBeenForcedToRefresh =
    JSON.parse(
      isSessionStorageAvailable &&
        sessionStorage?.getItem(
          "page-has-been-forced-to-refresh-by-component-loader",
        ),
    ) || "false";

  let refreshedComponents: Set<string>;

  if (Array.isArray(componentsRefreshed))
    refreshedComponents = new Set(componentsRefreshed);
  else throw Error("Unexpected value from data store");

  const hasComponentRefreshed = refreshedComponents?.has(stringifiedFunction);
  const hasToResolveComponentLoaderPromise =
    hasComponentRefreshed || !pageHasAlreadyBeenForcedToRefresh;

  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        captureMessage(
          "Página recarregando através de src/shared/utils/componentLoader",
        );

        if (hasToResolveComponentLoaderPromise) {
          resolve({ default: () => null });
          return;
        }

        if (+sessionStorageAttemptsLeft <= 1) {
          try {
            refreshedComponents?.add(stringifiedFunction);
            if (isSessionStorageAvailable) {
              setPageWasForcedToReload();
              setReloadedComponents(refreshedComponents);
            }
          } catch (error) {
            failedToAccessSessionStorage(error);
          }
          captureException(error);
          captureMessage(
            "Error (componentLoader): Failed when retrying to fetch the component.",
          );
          console.log(
            "Página recarregando através de src/shared/utils/componentLoader.js",
          );
        }

        try {
          if (isSessionStorageAvailable)
            setComponentLoaderAttemptsLeft(+sessionStorageAttemptsLeft);
        } catch (error) {
          failedToAccessSessionStorage(error);
        }

        if (reloadTimeoutId !== null) clearTimeout(reloadTimeoutId);

        reloadTimeoutId = setTimeout(() => window?.location?.reload(), 500);
      });
  });
};

export default componentLoader;
