import { useContext, useEffect } from "react";

import type { NextPage } from "next";
import { useRouter } from "next/navigation";

import CircularLoader from "shared/components/atoms/CircularLoader";
import GlobalContext from "shared/providers/context/GlobalContext";

import { captureException, captureMessage } from "@sentry/nextjs";

const Home: NextPage = () => {
  const router = useRouter();
  const { state } = useContext(GlobalContext);

  const tenancySettings = state.tenancy?.settings;

  useEffect(() => {
    try {
      const mainPage = tenancySettings?.site?.mainPage;

      if (mainPage) {
        console.log(
          `Navigating from [tenant]/home -> router?.push(/${mainPage})`,
        );
        router?.push(`/${mainPage}`);
      }
    } catch (error) {
      console.log(
        "[tenant]/home: an error occurred in the useEffect that redirects to main page.",
      );
      captureException(error);
      captureMessage(
        "[tenant]/home: an error occurred in the useEffect that redirects to main page.",
      );
    }
  }, [tenancySettings]);

  return (
    <>
      <div className="container-loading">
        <CircularLoader />
      </div>
    </>
  );
};

export default Home;
