import { useCallback, useEffect } from "react";

import { TenantData } from "shared/providers/context/initialStates/tenancy";

import { captureException, captureMessage } from "@sentry/nextjs";

interface IUseFetchInitialMenusProps {
  handleInitialPlatformThemeValues: ({ menus }: any) => void;
  query?: { tenant?: string; isMobileAppClient?: string };
  tenancy: TenantData;
}
const useFetchInitialMenus = ({
  handleInitialPlatformThemeValues,
  query,
  tenancy,
}: IUseFetchInitialMenusProps) => {
  const uuid = query?.tenant;

  const handleAppInitialTheme = useCallback(() => {
    try {
      handleInitialPlatformThemeValues({});
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that set 'appModeEnable' and 'theme' values.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [handleInitialPlatformThemeValues, tenancy, uuid]);

  useEffect(() => {
    handleAppInitialTheme();
  }, [handleAppInitialTheme]);

  return {};
};

export default useFetchInitialMenus;
